import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HashLocationStrategy, LocationStrategy } from '@angular/common'; // for browser issue

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
// import { SQLite } from '@ionic-native/sqlite/ngx';
// FOR FIREBASE DATABASE //
// import { AngularFireModule } from '@angular/fire';
// import { AngularFireAuthModule } from '@angular/fire/auth';
// import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireModule } from 'angularfire2';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFirestoreModule } from 'angularfire2/firestore';
// FOR STORAGE
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../environments/environment';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/*
import { LoginPage } from './pages/login/login.page';
import { LoginPageModule } from './pages/login/login.module';

import { RegisterPage } from './pages/register/register.page';
import { ResetPasswordPage } from './pages/reset-password/reset-password.page';
import { AutorecipientPage } from './pages/autorecipient/autorecipient.page';
import { EndlessrecipientPage } from './pages/endlessrecipient/endlessrecipient.page';

import { RegisterPageModule } from './pages/register/register.module';
import { ResetPasswordPageModule } from './pages/reset-password/reset-password.module';
import { StillalivePageModule } from './pages/stillalive/stillalive.module';
import { AutorecipientPageModule } from './pages/autorecipient/autorecipient.module';
import { EndlessrecipientPageModule } from './pages/endlessrecipient/endlessrecipient.module';
*/

// FOR TRANSLATION
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateConfigService } from './translate-config.service';
// import { Deeplinks } from '@ionic-native/deeplinks/ngx';

// FOR SERVICE
import { UserService } from './user.service';

// FOR IN-APP PURCHASE
import { InAppPurchase2 } from '@ionic-native/in-app-purchase-2/ngx';

export function LanguageLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/');
  // return new TranslateHttpLoader(http, './assets/i18n/', '.json');
  // return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}


// FOR FIREBASE DATABASE //
export const firebaseConfig = {
      apiKey: 'AIzaSyBY3eW-1VQYnuvemxi5C0tq3ctnOaEXanQ',
      authDomain: 'lastwords-3126a.firebaseapp.com',
      databaseURL: 'https://lastwords-3126a.firebaseio.com',
      projectId: 'lastwords-3126a',
      storageBucket: 'lastwords-3126a.appspot.com',
      messagingSenderId: '580082523326',
      appId: '1:580082523326:web:498b78a40bc4fcd9f692ef',
      measurementId: 'G-MQM5NB21ZB'
};


@NgModule({
  declarations: [
    AppComponent,


    // LoginPage,
    // RegisterPage,
    // ResetPasswordPage
  ],

  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,

   // FOR FIREBASE DATABASE //
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,

    AngularFireModule.initializeApp(environment.firebase, 'my-app-name'), // imports firebase/app needed for everything
    AngularFireStorageModule, // imports firebase/storage only needed for storage features

    FormsModule,
    ReactiveFormsModule,
/*
    LoginPageModule,    // <-- add module here instead of declaration

    RegisterPageModule,
    ResetPasswordPageModule,
    StillalivePageModule,
    AutorecipientPageModule,
    EndlessrecipientPageModule,
*/
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (LanguageLoader),
        deps: [HttpClient]
      }
    })

    ],
  entryComponents: [
/*
    LoginPage,

    RegisterPage,
    ResetPasswordPage,
    AutorecipientPage,
    EndlessrecipientPage,
*/
  ],
  providers: [
    StatusBar,
    SplashScreen,
    TranslateConfigService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LocationStrategy, useClass: HashLocationStrategy }, // for browser version issue
    File,
    FileOpener,
    EmailComposer,
    // Deeplinks,
    InAppPurchase2,
    UserService,
    // SQLite,

  ],

  bootstrap: [AppComponent]

})
export class AppModule {}



import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)},
  { path: 'menu', loadChildren: './pages/menu/menu.module#MenuPageModule' },
//  { path: 'register', loadChildren: './pages/register/register.module#RegisterPageModule' },
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' },
//  { path: 'reset-password', loadChildren: './pages/reset-password/reset-password.module#ResetPasswordPageModule' },
//  { path: 'selectcontent', loadChildren: './pages/selectcontent/selectcontent.module#SelectcontentPageModule' },
//  { path: 'profile', loadChildren: './pages/profile/profile.module#ProfilePageModule' },
//  { path: 'agreement', loadChildren: './pages/agreement/agreement.module#AgreementPageModule' },
//  { path: 'test', loadChildren: './pages/test/test.module#TestPageModule' },
//  { path: 'autosetup', loadChildren: './pages/autosetup/autosetup.module#AutosetupPageModule' },
//  { path: 'autoemail', loadChildren: './pages/autoemail/autoemail.module#AutoemailPageModule' },
//  { path: 'autorecipient', loadChildren: './pages/autorecipient/autorecipient.module#AutorecipientPageModule' },
//  { path: 'recipientfile', loadChildren: './pages/recipientfile/recipientfile.module#RecipientfilePageModule' },
  { path: 'adminmenu', loadChildren: './pages/adminmenu/adminmenu.module#AdminmenuPageModule' },
  { path: 'server', loadChildren: './pages/server/server.module#ServerPageModule' },
//  { path: 'afterdeath', loadChildren: './pages/afterdeath/afterdeath.module#AfterdeathPageModule' },
//  { path: 'adafrontpage', loadChildren: './pages/adafrontpage/adafrontpage.module#AdafrontpagePageModule' },
//  { path: 'autofrontpage', loadChildren: './pages/autofrontpage/autofrontpage.module#AutofrontpagePageModule' },
//  { path: 'adapreview', loadChildren: './pages/adapreview/adapreview.module#AdapreviewPageModule' },
  { path: 'language', loadChildren: './pages/language/language.module#LanguagePageModule' },
//  { path: 'willfrontpage', loadChildren: './pages/willfrontpage/willfrontpage.module#WillfrontpagePageModule' },
  { path: 'stillalive', loadChildren: './pages/stillalive/stillalive.module#StillalivePageModule' },
//  { path: 'upgrade', loadChildren: './pages/upgrade/upgrade.module#UpgradePageModule' },
//  { path: 'questions', loadChildren: './pages/questions/questions.module#QuestionsPageModule' },
//  { path: 'statistics', loadChildren: './pages/statistics/statistics.module#StatisticsPageModule' },
//  { path: 'manageusers', loadChildren: './pages/manageusers/manageusers.module#ManageusersPageModule' },
//  { path: 'verifyemail', loadChildren: './pages/verifyemail/verifyemail.module#VerifyemailPageModule' },
  { path: 'privacy', loadChildren: './pages/privacy/privacy.module#PrivacyPageModule' },
//  { path: 'endlessrecipient', loadChildren: './pages/endlessrecipient/endlessrecipient.module#EndlessrecipientPageModule' },
//  { path: 'endlessfile', loadChildren: './pages/endlessfile/endlessfile.module#EndlessfilePageModule' },
  { path: 'terms', loadChildren: './pages/terms/terms.module#TermsPageModule' },
//  { path: 'endlessfrontpage', loadChildren: './pages/endlessfrontpage/endlessfrontpage.module#EndlessfrontpagePageModule' },
//  { path: 'freetrial', loadChildren: './pages/freetrial/freetrial.module#FreetrialPageModule' },
//  { path: 'testnodemail', loadChildren: './pages/testnodemail/testnodemail.module#TestnodemailPageModule' },
//  { path: 'firstpage', loadChildren: './pages/firstpage/firstpage.module#FirstpagePageModule' },
//  { path: 'firstquestion', loadChildren: './pages/firstquestion/firstquestion.module#FirstquestionPageModule' },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { NavController, AlertController, Events } from '@ionic/angular';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore } from 'angularfire2/firestore';
import { TranslateConfigService } from '../../translate-config.service';
import { UserService } from '../../user.service';
import { TranslateService } from '@ngx-translate/core';
import { AngularFireStorage } from '@angular/fire/storage';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.page.html',
  styleUrls: ['./menu.page.scss'],
})
export class MenuPage implements OnInit {

  userId = '';
  Versions = [];
  selectVersion = '';
  subMenu = false;

  // FOR DELETE BUTTON
  AdaTaskList: any;
  AdaList: any;
  AdaRecipientTaskList: any;
  AdaRecipientList: any;
  AutosetupTaskList: any;
  AutosetupList: any;
  AutorecipientTaskList: any;
  AutorecipientList: any;
  RecipientfileTaskList: any;
  RecipientfileList: any;
  EndlessemailTaskList: any;
  EndlessemailList: any;
  EndlessrecipientTaskList: any;
  EndlessrecipientList: any;
  EndlessfileTaskList: any;
  EndlessfileList: any;
  ada = [];
  adaRecipient = [];
  autoSetup = [];
  autoRecipient = [];
  recipientFile = [];
  endlessEmail = [];
  endlessRecipient = [];
  endlessFile = [];


  // FOR LANGUAGE
  selectedLanguage: string;
  userData = [];
  userName: any;
  userEmail: any;
  expiryTime: any;
  premium1 = false;
  premium2 = false;
  vip = false;
  VIP = '';
  willExpiryTime: any;
  accessExpiryTime: any;
  readEndlessPage = '';
  freeTrial = '';
  admin = false;

  // THIS PAGES IS FOR ANOTHER SIDE MENU METHOD //
  pages = [
      {
        title: 'Home',
        url: '/menu',
        icon: 'home'
      },
      {
        title: 'Beneficiary',
        url: '/beneficiary',
        icon: 'people'
      },
      {
        title: 'Preview / Export',
        url: '/export',
        icon: 'download'
      },
      {
        title: 'Important Legal Advice',
        url: '/workshop',
        icon: 'list'
      },
      {
        title: 'Upload (Video/Voice/File)',
        url: '/recipientfile',
        icon: 'cloud-upload'
      },
      {
        title: 'Last Words Setup',
        url: '/autosetup',
        icon: 'timer'
      },
      {
        title: 'Upgrade to Premium User',
        url: '/menu',
        icon: 'rocket'
      },
      {
        title: 'Setting',
        icon: 'settings',
        children: [
            {
                title: 'Profile',
                url: '/profile',
                icon: 'person'
            },
            {
                title: 'Will Version',
                url: '/selectcontent',
                icon: 'globe'
            },
            {
                title: 'Language',
                url: '/selectcontent',
                icon: 'globe'
            },
            {
                title: 'User Agreement',
                url: '/agreement',
                icon: 'checkbox-outline'
            },
        ]
      },
  ];



  constructor(private navCtrl: NavController, public afAuth: AngularFireAuth,
              public firestore: AngularFirestore, public alertCtrl: AlertController,
              private translateConfigService: TranslateConfigService,
              private translate: TranslateService,
              public userService: UserService,
              public events: Events,
              private storage: AngularFireStorage) {

                  /* user service load only once even after logout, not workable
                  this.userName = this.userService.displayName;
                  this.userEmail = this.userService.email; */
              }


  ngOnInit() {}


  ionViewWillEnter() {
                // GET USER ID INFO AND SETUP DOCUMENT(TABLE) NAME
                this.afAuth.authState.subscribe(user => {
                  if (user) {
                      this.userId = user.uid;
                      const temp = this.firestore.collection('users').doc(this.userId).valueChanges();
                      // tslint:disable-next-line: deprecation
                      temp.subscribe(items => {
                          this.Versions.push(items);
                          this.selectVersion = this.Versions[0].version;
                          this.userData.push(items);
                          this.VIP = this.userData[0].vip;
                          this.selectedLanguage = this.userData[0].language;
                          this.expiryTime = new Date(this.userData[0].allAccessExpiry);
                          this.userName = this.userData[0].firstName;
                          this.userEmail = this.userData[0].email;
                          this.willExpiryTime = new Date(this.userData[0].willExpiry);
                          this.accessExpiryTime = new Date(this.userData[0].allAccessExpiry);
                          this.readEndlessPage = this.userData[0].readEndless;
                          this.freeTrial = this.userData[0].trialExpiry;

                          this.translateConfigService.setLanguage(this.selectedLanguage);

                          // CEHCK IF ADMIN
                          if (this.userEmail === 'info.mywill.team@gmail.com') {
                              this.admin = true;
                          }

                          // CEHCK IF VIP
                          if (this.VIP === 'Yes') {
                            this.vip = true;
                          }

                          // CHECK IF PREMIUM USER OR NOT
                          const currentTime = new Date();
                          if (this.willExpiryTime !== null && this.willExpiryTime >= currentTime) {
                              this.premium1 = true;
                          } else if (this.accessExpiryTime !== null && this.accessExpiryTime >= currentTime) {
                              this.premium2 = true;
                          }
                      });

                      // FOR DELETE BUTTON
                      this.AdaTaskList = this.firestore.doc<any>('users/' + this.userId).collection('ADA').valueChanges();
                      this.AdaList = this.firestore.doc<any>('users/' + this.userId).collection('ADA');
                      this.AdaRecipientTaskList = this.firestore.doc<any>('users/' + this.userId).collection('Adarecipient').valueChanges();
                      this.AdaRecipientList = this.firestore.doc<any>('users/' + this.userId).collection('Adarecipient');
                      this.AutosetupTaskList = this.firestore.doc<any>('users/' + this.userId).collection('Autosetup').valueChanges();
                      this.AutosetupList = this.firestore.doc<any>('users/' + this.userId).collection('Autosetup');
                      // tslint:disable-next-line: max-line-length
                      this.AutorecipientTaskList = this.firestore.doc<any>('users/' + this.userId).collection('Autorecipient').valueChanges();
                      this.AutorecipientList = this.firestore.doc<any>('users/' + this.userId).collection('Autorecipient');
                      // tslint:disable-next-line: max-line-length
                      this.RecipientfileTaskList = this.firestore.doc<any>('users/' + this.userId).collection('Recipientfile').valueChanges();
                      this.RecipientfileList = this.firestore.doc<any>('users/' + this.userId).collection('Recipientfile');
                      this.EndlessemailTaskList = this.firestore.doc<any>('users/' + this.userId).collection('Endlessemail').valueChanges();
                      this.EndlessemailList = this.firestore.doc<any>('users/' + this.userId).collection('Endlessemail');
                      // tslint:disable-next-line: max-line-length
                      this.EndlessrecipientTaskList = this.firestore.doc<any>('users/' + this.userId).collection('Endlessrecipient').valueChanges();
                      this.EndlessrecipientList = this.firestore.doc<any>('users/' + this.userId).collection('Endlessrecipient');
                      this.EndlessfileTaskList = this.firestore.doc<any>('users/' + this.userId).collection('Endlessfile').valueChanges();
                      this.EndlessfileList = this.firestore.doc<any>('users/' + this.userId).collection('Endlessfile');

                      this.AdaList.ref.get().then(async (querySnapshot) => {
                           querySnapshot.forEach((items) => {
                            this.ada.push(items.data());
                           });
                      });
                      this.AdaRecipientList.ref.get().then(async (querySnapshot) => {
                          querySnapshot.forEach((items) => {
                          this.adaRecipient.push(items.data());
                          });
                      });
                      this.AutosetupList.ref.get().then(async (querySnapshot) => {
                          querySnapshot.forEach((items) => {
                          this.autoSetup.push(items.data());
                          });
                      });
                      this.AutorecipientList.ref.get().then(async (querySnapshot) => {
                          querySnapshot.forEach((items) => {
                          this.autoRecipient.push(items.data());
                          });
                      });
                      this.RecipientfileList.ref.get().then(async (querySnapshot) => {
                          querySnapshot.forEach((items) => {
                          this.recipientFile.push(items.data());
                          });
                      });
                      this.EndlessemailList.ref.get().then(async (querySnapshot) => {
                          querySnapshot.forEach((items) => {
                          this.endlessEmail.push(items.data());
                          });
                      });
                      this.EndlessrecipientList.ref.get().then(async (querySnapshot) => {
                          querySnapshot.forEach((items) => {
                          this.endlessRecipient.push(items.data());
                          });
                      });
                      this.EndlessfileList.ref.get().then(async (querySnapshot) => {
                          querySnapshot.forEach((items) => {
                          this.endlessFile.push(items.data());
                          });
                      });

                      // end delete button

                  } // end if user
                 });

  }



  listSubMenu() {
      if (this.subMenu) {
          this.subMenu = false;
      } else {
          this.subMenu = true;
      }
  }

  goPageAdmin() {
    this.navCtrl.navigateRoot('/adminmenu');
  }

  goPageBeneficiary() {
      this.navCtrl.navigateRoot('/beneficiary');
  }

  goPagePreview() {
    this.navCtrl.navigateRoot('/export');
  }

  goPageWorkshop() {
    this.navCtrl.navigateRoot('/workshop');
  }

  goPageUpload() {
    const currentTime = new Date();
    if (this.expiryTime !== null && this.expiryTime >= currentTime) {
        this.navCtrl.navigateRoot('/autorecipient');
    } else {
        this.navCtrl.navigateRoot('/autofrontpage');
    }
  }

  goPageAutosetup() {
    this.navCtrl.navigateRoot('/autosetup');
  }


  goPageAda() {
    const currentTime = new Date();
    if (this.expiryTime !== null && this.expiryTime >= currentTime) {
        this.navCtrl.navigateRoot('/afterdeath');
    } else {
        this.navCtrl.navigateRoot('/adafrontpage');
    }
  }


  goPageAutoemail() {
    const currentTime = new Date();
    if (this.expiryTime !== null && this.expiryTime >= currentTime) {

        this.navCtrl.navigateRoot('/autosetup');

/*        // CHECK IF AUTOSETUP FILE EXISTED OR NOT
        const temp = this.firestore.collection('users').doc(this.userId).collection('Autosetup').valueChanges();
            // tslint:disable-next-line: deprecation
        temp.subscribe(items => {
                    if (items.length > 0) {
                        this.navCtrl.navigateRoot('/autoemail');
                    } else {
                        this.setupAlert();
                        this.navCtrl.navigateRoot('/menu');
                    }
             }); // end check */

    } else {
        this.navCtrl.navigateRoot('/autofrontpage');
    }
  }


  goPageEndlessemail() {
    if (this.readEndlessPage === 'Yes') {
        this.navCtrl.navigateRoot('/endlessrecipient');
    } else {
        this.navCtrl.navigateRoot('/endlessfrontpage');
    }
  }


  async goPageDelete() {
      const alert = await this.alertCtrl.create({
        cssClass: 'myalert',
        // header: 'ALERT',
        // message: 'Are you sure to delete Will data?',
        header: this.translate.instant('alert.header1'),
        subHeader: this.translate.instant('alert.message1'),
        message: this.translate.instant('alert.message1a'),
        buttons: [{ text: this.translate.instant('alert.buttoncancel'), role: 'cancel' },
                  { text: this.translate.instant('alert.buttonyes'), handler: () => {

                    this.deleteAllContent();
                    this.deleteDoneAlert();
                }
              }]
      });
      await alert.present();

  }


  deleteAllContent() { // DELETE ALL CONTENT
      if (this.ada.length > 0) {
        for (const item of this.ada) {
          this.AdaList.doc(item.DocId).delete();
        }
      }
      if (this.adaRecipient.length > 0) {
        for (const item of this.adaRecipient) {
          this.AdaRecipientList.doc(item.DocId).delete();
        }
      }
      if (this.autoSetup.length > 0) {
        for (const item of this.autoSetup) {
          this.AutosetupList.doc(item.DocId).delete();
        }
      }
      if (this.autoRecipient.length > 0) {
        for (const item of this.autoRecipient) {
          this.AutorecipientList.doc(item.DocId).delete();
        }
      }
      if (this.recipientFile.length > 0) {
        for (const item of this.recipientFile) {
          this.RecipientfileList.doc(item.DocId).delete();
        }
      }
      if (this.endlessEmail.length > 0) {
        for (const item of this.endlessEmail) {
          this.EndlessemailList.doc(item.DocId).delete();
        }
      }
      if (this.endlessRecipient.length > 0) {
        for (const item of this.endlessRecipient) {
          this.EndlessrecipientList.doc(item.DocId).delete();
        }
      }
      if (this.endlessFile.length > 0) {
        for (const item of this.endlessFile) {

          const thisUrl = item.filepath;
          this.storage.storage.refFromURL(thisUrl).delete(); // DELETE STORAGE IMAGE FILE

          this.EndlessfileList.doc(item.DocId).delete();
        }
      }
      // DELETE PURCHASED EMAIL & CAPACITY QUOTA
      const userDoc = this.firestore.doc<any>('users/' + this.userId);
      userDoc.update({
          emailQuota: 0,
          storageQuota: 0,
      });

  }


  async deleteDoneAlert() {
        const alert = await this.alertCtrl.create({
                cssClass: 'myalert',
                // header: 'ALERT',
                // subHeader: 'Subtitle',
                // message: 'Will data was deleted',
                header: this.translate.instant('alert.header1'),
                message: this.translate.instant('alert.message2'),
                buttons: ['OK']
        });
        alert.present();

  }


  goPageFreetrial() {
    if (this.freeTrial === '') {
        this.navCtrl.navigateRoot('/freetrial');
    } else {
      this.freeTrialAlert();
    }
  }

  goPageUpgrade() {
    this.navCtrl.navigateRoot('/upgrade');
  }

  goPageProfile() {
    this.navCtrl.navigateRoot('/profile');
  }

  goPageSelectcontent() {
    this.navCtrl.navigateRoot('/selectcontent');
  }

  goPageLanguage() {
    this.navCtrl.navigateRoot('/language');
  }

  goPageQuestions() {
    this.navCtrl.navigateRoot('/questions');
  }

  goPageAgreement() {
    this.navCtrl.navigateRoot('/agreement');
  }

  goPageWill() {   // ALSO NEED TO UPDATE BENEFICIARY PAGE
      if (this.selectVersion === 'hkEnglishSimple') {
          this.navCtrl.navigateRoot('/hkenglishsimple');
      } else if (this.selectVersion === 'hkChineseSimple') {
          this.navCtrl.navigateRoot('/hkchinesesimple');
      } else if (this.selectVersion === 'ChinaSimple') {
          this.navCtrl.navigateRoot('/v-chinasimple');
      } else if (this.selectVersion === 'SingaporeSimple') {
          this.navCtrl.navigateRoot('/v-singaporesimple');
      } else if (this.selectVersion === 'MalaysiaSimple') {
          this.navCtrl.navigateRoot('/v-malaysiasimple');
      } else if (this.selectVersion === 'UKSimple') {
          this.navCtrl.navigateRoot('/v-uksimple');

      } else if (this.selectVersion === 'hkEnglish') {
          this.checkUpgradeStatus('/hkenglish');
      } else if (this.selectVersion === 'hkChinese') {
          this.checkUpgradeStatus('/hkchinese');
      } else if (this.selectVersion === 'China') {
          this.checkUpgradeStatus('/v-china');
      } else if (this.selectVersion === 'Singapore') {
          this.checkUpgradeStatus('/v-singapore');
      } else if (this.selectVersion === 'Malaysia') {
          this.checkUpgradeStatus('/v-malaysia');
      } else if (this.selectVersion === 'UK') {
          this.checkUpgradeStatus('/v-uk');

      } else {
          this.selectWillAlert();
      }
  }


  checkUpgradeStatus(page) {
      const currentTime = new Date();
      if (this.accessExpiryTime !== '' && this.accessExpiryTime >= currentTime) {
          this.navCtrl.navigateRoot(page);
      } else {
          if (this.willExpiryTime !== '' && this.willExpiryTime >= currentTime) {
              this.navCtrl.navigateRoot(page);
          } else {
              this.premiumAlert();
          }
      }
  }


  async setupAlert() {
        const alert = await this.alertCtrl.create({
                cssClass: 'myalert',
                // header: 'ALERT',
                // subHeader: 'Subtitle',
                // message: 'Please setup After-Death-Arrangement first',
                header: this.translate.instant('alert.header1'),
                message: this.translate.instant('alert.message3'),
                buttons: ['OK']
        });
        alert.present();

  }


  async premiumAlert() {
      const alert = await this.alertCtrl.create({
              cssClass: 'myalert',
              // header: 'ALERT',
              // subHeader: 'This will version is exclusive to Premium User',
              // message: 'Please go to Settings and select other Simple Versions',
              header: this.translate.instant('alert.header1'),
              subHeader: this.translate.instant('alert.message4'),
              message: this.translate.instant('alert.message5'),
              buttons: ['OK']
      });
      alert.present();

  }


  async selectWillAlert() {
      const alert = await this.alertCtrl.create({
              cssClass: 'myalert',
              // header: 'ALERT',
              // subHeader: 'No Will Selected',
              // message: 'Please go back and select Will version',
              header: this.translate.instant('alert.header1'),
              subHeader: this.translate.instant('alert.message6'),
              message: this.translate.instant('alert.message7'),
              buttons: ['OK']
      });
      alert.present();

  }


  async freeTrialAlert() {
    const alert = await this.alertCtrl.create({
            cssClass: 'myalert',
            // header: 'ALERT',
            // subHeader: 'No Will Selected',
            // message: 'Please go back and select Will version',
            header: this.translate.instant('alert.header1'),
            // subHeader: this.translate.instant('alert.message6'),
            message: this.translate.instant('alert.message28'),
            buttons: ['OK']
    });
    alert.present();

}


  async logout() {
          const alert = await this.alertCtrl.create({
            cssClass: 'myalert',
            // header: 'ALERT',
            // message: 'Are you sure to logout?',
            header: this.translate.instant('alert.header1'),
            message: this.translate.instant('alert.message8'),
            buttons: [{ text: this.translate.instant('alert.buttoncancel'), role: 'cancel' },
                      { text: this.translate.instant('alert.buttonyes'), handler: () => {

                        return this.afAuth.auth.signOut().then(authData => {
                          // this.app.getRootNav().setRoot(LoginPage);
                          this.navCtrl.navigateRoot('/login');
                        });

                    }
                  }]
          });
          await alert.present();

  }




}
